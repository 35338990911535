import React from 'react'
import { useHistory } from 'react-router-dom'

import { ReactComponent as TherapistIcon } from 'i/icons/therapist.svg'
import { ReactComponent as BusinessIcon } from 'i/icons/business.svg'
import { ReactComponent as TechnologyIcon } from 'i/icons/tech.svg'
import { ReactComponent as PractitionerIcon } from 'i/icons/practitioner.svg'
import { ReactComponent as OtherIcon } from 'i/icons/other.svg'

const CategorySelect = (props) => {
  const history = useHistory()

  return (
    <section className="section pro_join_mod full_mod_v2">
      <div className="section_row cat_mod">
        <h2 className="section_title center_mod offset_mod xxl_mod">
          What type of professional are you?
        </h2>
        <div className="section_descr center_lvl_mod offset_mod cat_text_mod">
          Enter your hourly rate. I calls are under one hour they will be billed per
          minute pro rated
        </div>
        <div className="section_row flex_mod role_mod">
          <div className="section_role_pro">
            <div className="section_col role_mod cat_mod">
              <div className="section_col role_mod_inner">
                <div
                  className="user_img_w category_icon"
                  onClick={(e) => history.push('/pro/rate', { category: 'Therapist' })}
                >
                  <TherapistIcon className="user_img" />
                </div>
                <h3 className="section_title inline_mod l_mod">Therapist</h3>
              </div>
            </div>
          </div>
          <div className="section_role_pro">
            <div className="section_col role_mod cat_mod">
              <div className="section_col role_mod_inner">
                <div
                  className="user_img_w category_icon"
                  onClick={(e) => history.push('/pro/rate', { category: 'Practitioner' })}
                >
                  <PractitionerIcon className="user_img" />
                </div>
                <h3 className="section_title inline_mod l_mod">Practitioner</h3>
              </div>
            </div>
          </div>
          <div className="section_role_pro">
            <div className="section_col role_mod cat_mod">
              <div className="section_col role_mod_inner">
                <div
                  className="user_img_w category_icon"
                  onClick={(e) => history.push('/pro/rate', { category: 'Business' })}
                >
                  <BusinessIcon className="user_img" />
                </div>
                <h3 className="section_title inline_mod l_mod">Business</h3>
              </div>
            </div>
          </div>
          <div className="section_role_pro">
            <div className="section_col role_mod cat_mod">
              <div className="section_col role_mod_inner">
                <div
                  className="user_img_w category_icon"
                  onClick={(e) => history.push('/pro/rate', { category: 'Technology' })}
                >
                  <TechnologyIcon className="user_img" />
                </div>
                <h3 className="section_title inline_mod l_mod">Technology</h3>
              </div>
            </div>
          </div>
          <div className="section_role_pro">
            <div className="section_col role_mod cat_mod">
              <div className="section_col role_mod_inner">
                <div
                  className="user_img_w category_icon"
                  onClick={() => history.push(`/pro/rate`, { category: 'Other' })}
                >
                  <OtherIcon className="user_img" />
                </div>
                <h3 className="section_title inline_mod l_mod category_select">Other</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CategorySelect
