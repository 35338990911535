import React, { useState, useRef } from 'react'
import classNames from 'classnames'
import { ReactComponent as PenIcon } from 'i/icons/pen.svg'
import styles from './SummaryNotes.module.scss'
import { postCallSummary } from 'api/call'
import ContentEditable from 'react-contenteditable'

const convertListToParagraphs = (html) => {
  return html
    .replace(/<ul>/g, '')
    .replace(/<\/ul>/g, '')
    .replace(/<li>/g, '<p>')
    .replace(/<\/li>/g, '</p>')
}

function SummaryNotes({
  notes = '',
  callId,
  callType,
  onEnterEditMode,
  onExitEditMode,
  isEditable = true,
  isEditMode,
}) {
  const contentEditableRef = useRef(null)
  const [updatedNotes, setUpdatedNotes] = useState(() => {
    return convertListToParagraphs(notes)
  })

  const handleTextareaChange = ({ target }) => {
    setUpdatedNotes(target.value)
  }

  const enterEditMode = () => {
    onEnterEditMode?.()
    contentEditableRef?.current?.el?.current?.focus()
  }

  const saveUpdatedNotes = () => {
    postCallSummary(callType, callId, {
      meeting_notes: updatedNotes,
    })
    onExitEditMode?.()
  }

  const toggleMode = () => {
    if (isEditMode) {
      saveUpdatedNotes()
    } else {
      enterEditMode()
    }
  }
  return (
    <div className={classNames(styles.notes)}>
      <div className={styles.container}>
        <ContentEditable
          ref={contentEditableRef}
          html={updatedNotes}
          disabled={!isEditMode}
          onChange={handleTextareaChange}
        />
      </div>

      {isEditable && (
        <div className={styles.triggerWrap}>
          <button
            className={styles.trigger}
            type="button"
            aria-label="Change meeting notes"
            onClick={toggleMode}
          >
            {isEditMode ? (
              <div className={styles.label}>Save</div>
            ) : (
              <div className={styles.icon}>
                <PenIcon />
              </div>
            )}
          </button>
        </div>
      )}
    </div>
  )
}

export { SummaryNotes }
