import React, { useState } from 'react'
import classNames from 'classnames'

import styles from './ShareButton.module.scss'

const ShareButton = ({ userChannelName }) => {
  const [isCopied, setCopied] = useState(false)
  const buttonClasses = classNames(styles.shareButton, {
    [styles.shareButtonSuccess]: isCopied,
  })

  const onClick = () => {
    const userUrl = `https://www.talkpro.com/${userChannelName}`

    navigator.clipboard.writeText(userUrl)
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  return (
    <div className={styles.share}>
      <button className={buttonClasses} type="button" onClick={onClick}>
        {isCopied ? 'Link has been copied!' : 'Share pro link'}
      </button>
    </div>
  )
}

export default ShareButton
