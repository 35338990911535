import React, { useContext } from 'react'
import classNames from 'classnames'
import { UiContext } from 'context/ui'

import styles from './DashboardUserInfoCard.module.scss'

import { ReactComponent as CheckIcon } from 'i/icons/check_4.svg'
import { ReactComponent as LockIcon } from 'i/icons/lock_2.svg'
import { ReactComponent as VoiceIcon } from 'i/icons/voice.svg'
import { ReactComponent as ScreenIcon } from 'i/icons/screen.svg'

const DashboardUserInfoCard = ({ title, data }) => {
  const { setActivePopupId } = useContext(UiContext)
  return (
    <div className={styles.card}>
      <div className={styles.title}>{title}</div>
      <ul className={styles.list}>
        {data.length &&
          data.map(({ title, completed, popupID, icon }, index) => {
            return (
              <li className={styles.item} key={index}>
                {!popupID && (
                  <div className={styles.element}>
                    {icon && (
                      <div className={styles.icon}>
                        {icon === 'lock' && <LockIcon />}
                        {icon === 'voice' && <VoiceIcon />}
                        {icon === 'screen' && <ScreenIcon />}
                      </div>
                    )}
                    {title}
                  </div>
                )}
                {popupID && (
                  <button
                    className={classNames(styles.link, {
                      [styles.linkCompleted]: completed,
                    })}
                    onClick={(e) => {
                      e.preventDefault()
                      setActivePopupId(popupID)
                    }}
                  >
                    <div className={styles.icon}>
                      <CheckIcon />
                    </div>
                    {title}
                  </button>
                )}
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default DashboardUserInfoCard
