import React, { useState, useContext } from 'react'
import classNames from 'classnames'
import { SummaryPlayer } from './ui/SummaryPlayer/SummaryPlayer'
import { SummaryNotes } from './ui/SummaryNotes/SummaryNotes'
import { LogoutButton } from 'components/LogoutButton'
import { ReactComponent as Logo } from 'i/logo.svg'
import styles from './SummaryPage.module.scss'
import { SummaryTranscription } from './ui/SummaryTranscription/SummaryTranscription'
import { useParams } from 'react-router-dom'
import { callApi } from 'api'
import { POPUPS_ID, PROMISE_STATES } from 'utils/constants'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { connect } from 'react-redux'
import getCallType from 'utils/getCallType'
import calculateFormattedDateTime from 'utils/calculateFormattedDateTime'
import useFetchWithRetry from 'hooks/useFetchWithRetry'
import SummaryPopup from 'components/Popups/SummaryPopup/SummaryPopup'
import { ReactComponent as LogoImage } from 'i/logo.svg'
import { ReactComponent as BackIcon } from 'i/icons/triangle-left.svg'

import { UiContext } from 'context/ui'
import { Button } from 'components/ui/Button'
import { LoadingBarBlock } from 'components/LoadingBarBlock'

const TABS = {
  nav: [
    {
      tabName: 'notes',
      title: 'Meeting notes',
    },
    {
      tabName: 'transcript',
      title: 'Transcript',
    },
    {
      tabName: 'video',
      title: 'Video',
    },
  ],
}

const SummaryPage = (props) => {
  const [activeTab, setActiveTab] = useState(TABS.nav[0].tabName)
  const [errorText, setErrorText] = useState(null)
  const [successText, setSuccessText] = useState(null)
  const [sendingMessageStatus, setSendingMessageStatus] = useState(PROMISE_STATES.idle)
  const { id } = useParams()
  const callType = getCallType()
  const [isEditMode, setEditMode] = useState(false)

  const { loading, error, data } = useFetchWithRetry(() =>
    callApi.getCallSummary(callType, id)
  )

  const { activePopupId, setActivePopupId } = useContext(UiContext)

  const history = useHistory()

  if (loading === PROMISE_STATES.rejected && error) {
    if (props.isPro) {
      history.push('/dashboard/pro')
    } else {
      history.push('/dashboard/user')
    }
  }

  const shareHandler = async () => {
    if (data.result.data.title === 'Instant group call') {
      setActivePopupId(POPUPS_ID.summary)
    } else {
      setSendingMessageStatus(PROMISE_STATES.pending)
      await callApi.shareMessage(callType, id).then((res) => {
        if (res.error) {
          setErrorText(res.error.data.detail)
        } else {
          setErrorText(null)
          setSuccessText('Shared successfully!')
          setSendingMessageStatus(PROMISE_STATES.fulfilled)
          setTimeout(() => {
            setSuccessText(null)
            setSendingMessageStatus(PROMISE_STATES.idle)
          }, 1000)
        }
      })
    }
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }

  const localTimeResult = calculateFormattedDateTime(
    data?.result.data.scheduledAt,
    'Etc/Greenwich',
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  return (
    <section className={classNames('section', styles.section)}>
      <SummaryPopup
        isOpen={activePopupId === POPUPS_ID.summary}
        callId={props.match.params.id}
      />
      <a
        href="/dashboard/pro"
        className={classNames(styles.back, {
          [styles.backV2]: props.isPro,
        })}
      >
        <div class={styles.backIcon}>
          <BackIcon />
        </div>
        {'Back'}
      </a>
      {loading === PROMISE_STATES.pending && !data?.result?.data?.meetingNotes ? (
        <LoadingBarBlock title="Meeting notes are being generated" />
      ) : null}
      {loading === PROMISE_STATES.fulfilled && data?.result?.data?.meetingNotes ? (
        <>
          {!props.isPro && <LogoutButton className={styles.logout} text="Logout" />}
          <div className={styles.head}>
            <div className={styles.logo}>
              <Logo />
            </div>
            <div className={styles.title}>Events Platform Review</div>
            {data?.result?.data?.scheduledAt && (
              <div className={styles.date}>{localTimeResult}</div>
            )}
            {props.isPro && (
              <>
                <Button
                  onClick={shareHandler}
                  sendingMessageStatus={sendingMessageStatus}
                  variant="quinary"
                  successVariant="quaternary"
                  size="md2"
                >
                  Share with attendees
                </Button>
              </>
            )}
          </div>

          <div className={styles.tabs}>
            {/* <div className={styles.nav}>
              {TABS.nav.map(({ title, tabName }) => (
                <button
                  key={tabName}
                  type="button"
                  className={classNames(styles.button, {
                    [styles.active]: tabName === activeTab,
                  })}
                  onClick={() => handleTabClick(tabName)}
                >
                  {title}
                </button>
              ))}
            </div> */}
            <div
              className={classNames(styles.body, {
                [styles.editMode]: isEditMode,
              })}
            >
              {activeTab === 'notes' && (
                <SummaryNotes
                  notes={data?.result?.data?.meetingNotes}
                  isEditable={props.isPro}
                  isEditMode={isEditMode}
                  callType={callType}
                  callId={id}
                  onEnterEditMode={() => {
                    setEditMode(true)
                  }}
                  onExitEditMode={() => {
                    setEditMode(false)
                  }}
                />
              )}
              {activeTab === 'transcript' && <SummaryTranscription />}
              {activeTab === 'video' && <SummaryPlayer roomID={id} />}
            </div>
          </div>
        </>
      ) : null}
    </section>
  )
}

const mapStateToProps = ({ auth: { error, isPro, user } }) => ({
  authError: error,
  user,
  isPro,
})

export default connect(mapStateToProps)(SummaryPage)
