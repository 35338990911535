import LogoImg from 'i/talkpro_logo.png'
import SignImg from 'i/signup_img.jpg'
import PayPalImg from 'i/paypal.png'
import { BaseNavigation } from 'components/ui/BaseNavgiation'
import { SERVICE_NAVIGATION } from 'shared/constants'

const SignUp3 = () => {
  return (
    <section class="section">
      <div class="signup__row">
        <div class="signup__col">
          <div class="signup__img">
            <img class="signup__img_in" src={SignImg} alt="" />
          </div>
          <BaseNavigation navigation={SERVICE_NAVIGATION} isAuthPage />
        </div>
        <div class="signup__col">
          <div class="signup__col_in">
            <div class="signup__logo_w">
              <a class="signup__logo" href="#">
                <img class="signup__logo_in" src={LogoImg} />
              </a>
            </div>
            <div class="signup__info">
              <h1 class="signup__title">Create Account</h1>
              <div class="signup__subtitle">Talkpro.com/Steve</div>
              <div class="signup__descr">
                You don’t need to remember an email and password, plus we’ll send you a
                reminders before your talk
              </div>
            </div>

            <div class="signup__tabs">
              <div class="signup__tabs_head">
                <div class="signup__tabs_item">
                  <a class="signup__tabs_link" href="#">
                    Rate and Profile
                  </a>
                </div>
                <div class="signup__tabs_item">
                  <a class="signup__tabs_link signup__tabs_link--active" href="#">
                    Payout Sync
                  </a>
                </div>
                <div class="signup__tabs_item_descr">
                  You will be asked for payment in the next step{' '}
                </div>
              </div>
              <div class="signup__tabs_content">
                <div class="signup__tabs_content_item signup__tabs_content_item--active">
                  <div class="signup__payouts_step">
                    <a href="#" class="signup__payouts_btn">
                      <img class="signup__payouts_btn_img" src={PayPalImg}></img>
                    </a>

                    <a href="#" class="signup__payouts_links">
                      What if I don’t have a PayPal account?
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SignUp3
