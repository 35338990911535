import React from 'react'
import { Link } from 'react-router-dom'
import Lottie from 'react-lottie'
import { format } from 'date-fns'

import animationData from 'i/lottie/Scene6.json'

const ConfirmedPage = (props) => {
  const windowWidth = window.innerWidth
  const callDetails = props.location.state

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  }

  return (
    <section className="section default_mod center_mod">
      <div className="section_in v3_mod">
        <div className="section_title center_lvl_mod offset_mod">Confirmed!</div>
        <div className="pro_lottie_wrap">
          {windowWidth >= 1024 ? (
            <Lottie options={lottieOptions} height={300} width={300} />
          ) : (
            <Lottie options={lottieOptions} height={200} width={200} />
          )}
        </div>
        {callDetails.event ? (
          <div className="section_title center_lvl_mod offset_mod">
            {format(new Date(callDetails.timeSelect), 'EEEE, LLLL do h:mma')}
            <div className="section_descr center_lvl_mod offset_mod">
              You have joined the event with {callDetails.pro?.user.name.split(' ')[0]}
            </div>
          </div>
        ) : (
          <>
            <div className="section_title center_lvl_mod offset_mod">
              {callDetails.pro?.user?.name} <br />{' '}
              {format(new Date(callDetails.timeSelect), 'EEEE, LLLL do h:mma')}
            </div>
            <div className="section_descr center_lvl_mod offset_mod">
              {callDetails.pro?.user.name.split(' ')[0]} will confirm the talk within 24
              hours
            </div>
          </>
        )}
        <div className="btn_wrap center_mod offset_2_mod">
          <Link className="btn_base" to="/dashboard/user">
            Go to Dashboard
          </Link>
        </div>
      </div>
    </section>
  )
}

export default ConfirmedPage
